import classNames from "classnames"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import React from "react"
import "./card.sass"

const Card = ({ horizontal, img, imgColor, children }) => {
  return (
    <div
      className={classNames({
        card: true,
        "card-horizontal": horizontal,
      })}
    >
      <Img fluid={img} backgroundColor={imgColor} className="card-img" />
      <div className="card-content">{children}</div>
    </div>
  )
}

Card.propTypes = {
  horizontal: PropTypes.bool,
  img: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node.isRequired,
}

export default Card
